import { ChipGroup } from '@kidsmanager/ui-core';
import { IRole, ROLE_LABELS } from '@kidsmanager/util-models';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';

export const AdminUserPermissions = () => {
  const context = useOutletContext<OutletContextAdminUser>();
  const { selectedUser, groups } = context;
  const allRoles: IRole[] = Array.from(ROLE_LABELS.keys());

  return (
    <div className="flex max-w-2xl flex-col gap-4">
      <ChipGroup
        chips={
          allRoles?.map((r) => ({
            id: r,
            label: ROLE_LABELS.get(r) || 'r'
          })) || []
        }
        selectedIds={selectedUser.roles?.map((s) => s.toString()) || []}
        label="Rollen"
        onChange={(ids: string[]) =>
          context.onUserChanged({ roles: ids?.map((s) => s as IRole) }, false)
        }
      />
      <ChipGroup
        chips={groups?.map((g) => ({ id: g.id, label: g.name })) || []}
        selectedIds={selectedUser.groupIds || []}
        label="Gruppen"
        onChange={(ids: string[]) =>
          context.onUserChanged({ groupIds: ids }, false)
        }
      />
    </div>
  );
};
