import { emptyUserData, UserData, TeamMember } from '../models';

const MaxAdjustment = 15;

export const buildUsers = (
  users: TeamMember[],
  publicHols: Date[],
  year: number,
  month: number
): UserData[] => {
  if (publicHols.length === 0 || users.length === 0) {
    console.warn('No public holidays or users, not populating roster');
    return Array.from({ length: 8 }).map((_, i) => ({
      ...emptyUserData,
      id: `fake-${i}`
    }));
  }
  const start = new Date(year, month - 1, 1);
  const end = new Date(year, month, 0);
  const workingDays = start.workingDaysUntil(end, publicHols);
  return users.map((u) => buildUser(u, workingDays));
};

export const buildUser = (user: TeamMember, workingDays: number): UserData => {
  const hoursPerDay = user.hours / 5;
  const holidays = user.holidays * hoursPerDay;
  const carryOver = user.carryOver;
  const hoursInMonth = hoursPerDay * workingDays;
  const maxCarryOver =
    carryOver > 0
      ? Math.min(MaxAdjustment, carryOver)
      : Math.max(-MaxAdjustment, carryOver);
  const goal = hoursInMonth - maxCarryOver - holidays;

  return {
    id: user.id,
    dutyRosters: user.dutyRosters || '',
    name: user.firstname,
    contractedHrs: user.hours,
    holidays,
    carryOver,
    hoursInMonth,
    workingDays,
    adjustment: -maxCarryOver,
    goal,
    available: goal
  };
};
