import { IDirectoryGroup, IDirectoryUser } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiDirectory {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async users(): Promise<IDirectoryUser[]> {
    return cache.get(`user-all`, async () => {
      return (await this.fetch(`api/directory/users`)).json();
    });
  }

  async groups(): Promise<IDirectoryGroup[]> {
    return cache.get(`group-all`, async () => {
      return (await this.fetch(`api/directory/groups`)).json();
    });
  }
}
