import { IconGoogle, Input, LinkButton } from '@kidsmanager/ui-core';
import { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { useTranslation } from 'react-i18next';
import { IGroup } from '@kidsmanager/util-models';
import { hasModule, Modules } from '@kidsmanager/util-models';

export const AdminGroupGeneral = () => {
  const userForm = useRef<HTMLFormElement>(null);
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.general';

  const { selected, modules } = context;

  const handleLinkGoogle = () => {
    console.log('handle to add google');
  };

  useEffect(() => {
    const handleReset = (event: unknown) => {
      userForm.current?.reset();
    };
    document.addEventListener('adminGroupAction', handleReset);
    return () => window.removeEventListener('adminGroupAction', handleReset);
  }, []);

  const getGroupDataFromForm = (
    formEvent: HTMLFormElement
  ): Partial<IGroup> | undefined => {
    const data = new FormData(formEvent);
    const address = {
      street: data.get('street')?.toString(),
      postcode: data.get('postcode')?.toString(),
      city: data.get('city')?.toString()
    };
    return {
      id: selected.id,
      name: data.get('name')?.toString(),
      general: {
        google: selected.general?.google || false,
        address: address
      }
    };
  };

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    const formGroup = getGroupDataFromForm(e.currentTarget);
    if (formGroup) {
      selected.general = formGroup.general || {};
      selected.name = formGroup.name || selected.name;
      context.onChange?.();
    }
  };

  return (
    <>
      <form
        ref={userForm}
        autoComplete="off"
        key={selected.id}
        className="flex flex-col gap-3"
        onBlur={handleOnChange.bind(this)}
        onChange={handleOnChange.bind(this)}
      >
        <Input
          name="name"
          label={t(`${i18nPrefix}.name`)}
          defaultValue={selected.name}
        />
        <Input
          name="street"
          label={t(`${i18nPrefix}.street`)}
          defaultValue={selected.general.address?.street}
        />
        <Input
          name="postcode"
          label={t(`${i18nPrefix}.postcode`)}
          defaultValue={selected.general.address?.postcode}
        />
        <Input
          name="city"
          label={t(`${i18nPrefix}.city`)}
          defaultValue={selected.general.address?.city}
        />
      </form>

      {hasModule(modules, Modules.Google) && (
        <div className="mt-8">
          <LinkButton onClick={handleLinkGoogle.bind(this)}>
            <div className="flex gap-2">
              <IconGoogle />
              {t(`${i18nPrefix}.google`)}
            </div>
          </LinkButton>
        </div>
      )}
    </>
  );
};
