import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { applyMask, maskToInputMode } from './input-masks';
import { applyBlur } from './input-blurs';
import { MaskOptions } from './mask-options';

export interface InputProps {
  id?: string;
  name?: string;
  type?: 'text' | 'number' | 'password';
  value?: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  label?: string;
  prefix?: string | undefined | null | ReactNode;
  suffix?: string | undefined | null | ReactNode;
  spellCheck?: boolean;
  autocomplete?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  mask?: MaskOptions;
  hint?: ReactNode;
}

export const Input = (props: InputProps) => {
  const type = props.type || 'text';

  const alignRight = props.mask === 'time' || props.mask === 'number';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!props.onChange) {
      e.target.value = applyMask(e.target.value, props.mask) || '';
    } else {
      props.onChange(applyMask(e.target.value, props.mask) || '');
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = applyBlur(
      e.target.value,
      props.type,
      props.min,
      props.max,
      props.mask
    );
    if (value !== e.target.value) {
      if (!props.onChange) {
        e.target.value = value;
      }
      props.onChange?.(value || '');
    }
    props.onBlur?.(value);
  };

  const content = (
    <div className="outline-focus inline-flex h-8 w-full max-w-md rounded-md border bg-white outline-2 outline-offset-2 focus-within:outline">
      {props.prefix && (
        <div className="flex items-center px-1">{props.prefix}</div>
      )}
      <input
        id={props.id}
        name={props.name}
        autoComplete={props.autocomplete || 'off'}
        spellCheck={props.spellCheck}
        className={`h-7 w-full flex-1 pr-2 outline-none placeholder:text-sm disabled:text-black/60 ${!props.prefix && 'pl-2'} ${alignRight && 'text-right'} rounded-md`}
        type={type}
        value={props.value}
        defaultValue={props.defaultValue}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        onChange={handleChange.bind(this)}
        onBlur={handleBlur.bind(this)}
        onFocusCapture={props.onFocus?.bind(this)}
        onKeyDown={props.onKeyDown?.bind(this)}
        inputMode={maskToInputMode(props.mask)}
        onFocus={props.mask === 'time' ? (e) => e.target.select() : undefined}
        disabled={props.disabled}
      />
      {props.suffix && (
        <div className="relative flex items-center px-1">{props.suffix}</div>
      )}
    </div>
  );

  return props.label ? (
    <label className="form-control relative w-full max-w-md">
      <div className="label">
        <span className="text-sm font-medium">{props.label}</span>
      </div>
      {content}
      {props.hint && (
        <div className="text-error absolute right-0 pt-0.5 text-xs">
          {props.hint}
        </div>
      )}
    </label>
  ) : (
    <div className="relative w-full">
      {content}
      {props.hint && (
        <div className="text-error absolute right-0 pt-0.5 text-xs">
          {props.hint}
        </div>
      )}
    </div>
  );
};
