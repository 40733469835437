import { IApproval } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiTimesheetApprovals {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async list(year: number, userId?: string): Promise<IApproval[]> {
    userId = userId || 'my';
    return cache.get(`summaries-${userId}-${year}`, async () =>
      (
        await this.fetch(`/api/timesheet/${userId}/approval?year=${year}`)
      ).json()
    );
  }

  async set(
    year: number,
    month: number,
    planned: number,
    actual: number,
    forUser?: string
  ) {
    forUser = forUser || 'my';
    cache.clear(`summaries-${forUser}-${year}`);
    return (
      await this.fetch(
        `/api/timesheet/${forUser}/approval?month=${year}-${month}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            date: new Date().toISOString(),
            planned,
            actual
          })
        }
      )
    ).json();
  }

  async clear(year: number, month: number, forUser?: string) {
    forUser = forUser || 'my';
    cache.clear(`summaries-${forUser}-${year}`);
    return (
      await this.fetch(
        `/api/timesheet/${forUser}/approval?month=${year}-${month}`,
        {
          method: 'DELETE'
        }
      )
    ).json();
  }
}
