import { IRosterTemplateShift, IShiftSpec } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiRosterShifts {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async list(
    year: number,
    month: number,
    ...groups: string[]
  ): Promise<IShiftSpec[]> {
    groups = [...new Set(groups)];
    groups.sort();
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-${groups.join('-')}-${iso_month}`, async () => {
      return (
        await this.fetch(
          `api/roster/shift?month=${iso_month}${groups.map((id) => `&id=${id}`).join('')}`
        )
      ).json();
    });
  }

  async for(
    year: number,
    month: number,
    userId?: string
  ): Promise<IShiftSpec[]> {
    const iso_month = new Date(year, month - 1).toLocaleISOMonth();
    return cache.get(`shifts-for-${iso_month}`, async () => {
      return (
        await this.fetch(
          `api/roster/shift?month=${iso_month}&id=${userId || 'my'}`
        )
      ).json();
    });
  }

  async update(
    year: number,
    month: number,
    groupId: string,
    shifts: IShiftSpec[]
  ): Promise<IShiftSpec[]> {
    const projected = shifts.map((shift) => ({
      id: shift.id,
      name: shift.name,
      start: shift.start,
      duration: shift.duration,
      created: shift.created
    }));
    const date = new Date(year, month - 1).toLocaleISOMonth();
    const url = `api/roster/shift?month=${date}&id=${groupId}`;
    const body = JSON.stringify(projected);
    const response = await this.fetch(url, {
      method: 'PUT',
      body
    });
    cache.clear(
      `shifts-${groupId}-${new Date(year, month - 1).toLocaleISOMonth()}`
    );
    return response.json();
  }
}
