const toTicks = (value: string): number => {
  const [h, m] = value.split(':').map((x) => parseInt(x));
  if (isNaN(h) || isNaN(m)) return Number.NaN;
  return new Date(new Date().setHours(h, m)).getTime();
};

export const hrsFromStartAndEnd = (
  start: string,
  end: string,
  prevHrs: number
): number => {
  try {
    const startTicks = toTicks(start);
    const endTicks = toTicks(end);
    if (isNaN(startTicks) || isNaN(endTicks)) return prevHrs;

    const hrs = (toTicks(end) - toTicks(start)) / (1000 * 60 * 60);
    if (hrs < 0) return prevHrs;
    return hrs;
  } catch {
    return prevHrs;
  }
};

export const endFromStartAndHours = (start: string, hours: number): string => {
  if (!start || Number.isNaN(hours)) return '';
  const x = new Date(toTicks(start) + hours * 60 * 60 * 1000);
  return `${x.getHours().toString().padStart(2, '0')}:${x.getMinutes().toString().padStart(2, '0')}`;
};
