import { useMemo, useState } from 'react';
import { Input } from '../input/input';

export interface ValuePercentProps {
  value: number;
  max: number;
  onChange?: (value: number) => void;
}

export const ValuePercent = (props: ValuePercentProps) => {
  const [hours, setHours] = useState(props.value.toString());

  const percent = useMemo(
    () => (props.max ? (parseFloat(hours) / props.max) * 100 : 0).toFixed(0),
    [hours, props.max]
  );

  const handleBlur = () => {
    if (props.onChange) {
      props.onChange(parseFloat(hours));
    }
  };

  return (
    <div className="flex space-x-2">
      <span className="w-14">
        <Input
          mask="number"
          min={0}
          max={props.max}
          value={hours}
          onChange={setHours}
          onBlur={handleBlur.bind(this)}
        />
      </span>
      <span className="w-16">
        <Input disabled value={`${percent}%`} />
      </span>
    </div>
  );
};
