import { ITemplateSummary } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminTemplate {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  summary(): Promise<ITemplateSummary[]> {
    return cache.get('template_summary', async () => {
      const result = await this.fetch('api/admin/template?summary=true');
      return result.ok ? result.json() : [];
    });
  }
}
