import { Badge } from '@kidsmanager/ui-core';
import { useEffect, useMemo, useState } from 'react';

export interface ShiftSpecProps {
  name: string;
  symbol?: string;
  index?: number;
  color: string;
  duration: number;
  start: string;
  end: string;
  selected?: boolean;
  hide?: boolean;
  infoOnly?: boolean;
  tip?: string;
  onSelected?: (selected: boolean) => void;
}

export const ShiftSpec = (props: ShiftSpecProps) => {
  const [selected, setSelected] = useState(props.selected);

  const meta = useMemo(() => {
    const days = props.duration <= 24 ? 0 : Math.floor(props.duration / 24);
    return `${props.start} - ${props.end}${days > 0 ? ` + ${days}d` : ''}`;
  }, [props.start, props.end, props.duration]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const onSelect = () => {
    if (props.infoOnly) return;
    if (props.onSelected) {
      props.onSelected(!selected);
    } else {
      setSelected((prev) => !prev);
    }
  };

  return (
    <div
      className={`grid grid-cols-[40px_1fr_auto] items-center gap-x-2 rounded-md px-2 py-1 ${props.infoOnly ? '' : 'cursor-pointer'}`}
      style={{
        backgroundColor: selected ? '#d9d9d9' : 'transparent'
      }}
      onClick={onSelect.bind(this)}
      title={props.tip}
    >
      <Badge {...props} />
      <div style={{ opacity: props.infoOnly ? 0.5 : 1 }}>
        <div className="flex items-center text-sm">
          {props.name}
          {props.infoOnly && (
            <span className="mx-2 text-nowrap rounded-full bg-gray-600 px-2 text-[11px] leading-4 text-white">
              Nur Info
            </span>
          )}
        </div>
        <div className="text-xs text-gray-500">{meta}</div>
      </div>
    </div>
  );
};
