import { quickid } from '@kidsmanager/ui-api';
import { IconButton, Input, LinkButton } from '@kidsmanager/ui-core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';

export const AdminGroupAddressbook = () => {
  const { t } = useTranslation('admin');
  const context = useOutletContext<OutletContextAdminGroups>();
  const [addresses, setAddresses] = useState<
    { id: string; name: string; readonly: boolean }[]
  >([]);

  const { selected } = context;

  useEffect(() => {
    const list: { id: string; name: string; readonly: boolean }[] = [];
    if (selected.general.address?.street) {
      const { street, city, postcode } = selected.general.address || {};
      list.push({
        id: 'default',
        name: `${street}, ${postcode} ${city}`,
        readonly: true
      });
    }
    selected.addressbook.forEach((a) => list.push({ ...a, readonly: false }));
    setAddresses(list);
  }, [selected]);

  const raiseChange = (
    update: { id: string; name: string; readonly: boolean }[]
  ) => {
    setAddresses(update);
    selected.addressbook = update
      .filter((x) => !x.readonly)
      .map(({ id, name }) => ({ id, name }));
    context.onChange?.();
  };

  const handleEdit = (id: string, newName: string) => {
    raiseChange(
      addresses.map((item) =>
        item.id === id ? { ...item, name: newName } : item
      )
    );
  };

  const handleDelete = (id: string) => {
    raiseChange(addresses.filter((item) => item.id !== id));
  };

  const handleAddAddress = () => {
    raiseChange([
      ...addresses,
      { id: quickid('AD'), name: '', readonly: false }
    ]);
  };

  return (
    <div className="max-w-lg">
      <div
        key={selected.id}
        className="min-h-52 rounded-md border border-black/10 bg-white px-2 py-2"
      >
        {addresses.map((item) => (
          <div className="mb-2 flex justify-start gap-2" key={item.id}>
            <div className="flex-1">
              <Input
                type="text"
                disabled={item.readonly}
                placeholder={t('groups.addressbook.placeholder')}
                value={item.name}
                onChange={handleEdit.bind(this, item.id)}
              ></Input>
            </div>
            <IconButton
              disabled={item.readonly}
              onClick={handleDelete.bind(this, item.id)}
            >
              delete
            </IconButton>
          </div>
        ))}
      </div>
      <div className="mt-0.5 flex">
        <LinkButton onClick={handleAddAddress.bind(this)}>
          + {t('common.add')}
        </LinkButton>
        <div className="flex-1 text-right text-sm opacity-60">
          {t('groups.addressbook.description', { count: addresses.length })}
        </div>
      </div>
    </div>
  );
};
