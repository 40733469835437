import { useContext, useEffect, useState } from 'react';
import { Button, GhostButton, LinkButton } from '@kidsmanager/ui-core';
import { nameToColor } from '@kidsmanager/util-common';
import { IRosterTemplateShift } from '@kidsmanager/util-models';
import { ClientBackendContext, quickid } from '@kidsmanager/ui-api';
import { InternalShiftSpec } from '../models';
import { endFromStartAndHours } from '../helpers';
import { EditableShiftSpec } from './editable-shift-spec';

export interface ShiftEditorProps {
  specs: InternalShiftSpec[];
  group: string;
  onCancel?: () => void;
  onSave?: (specs: InternalShiftSpec[]) => void;
}

export const ShiftEditor = (props: ShiftEditorProps) => {
  const [needsSave, setNeedsSave] = useState(false);
  const [specs, setSpecs] = useState<InternalShiftSpec[]>([]);
  const [patterns, setPatterns] = useState<IRosterTemplateShift[]>([]);
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    client.roster.config.patterns(props.group).then(setPatterns.bind(this));
  }, [props.group, client]);

  useEffect(() => {
    setSpecs(
      props.specs.filter((x) => !x.readonly).map((x, i) => ({ ...x, index: i }))
    );
  }, [props.specs]);

  const handleAdd = () => {
    const primary = patterns[0];
    const newSpec: InternalShiftSpec = {
      id: quickid('SS'),
      index: specs.length,
      groupId: props.group,
      name: primary.name,
      duration: primary.duration,
      start: primary.start,
      end: endFromStartAndHours(primary.start, primary.duration),
      color: nameToColor(primary.name),
      created: new Date().toLocaleISODate()
    };
    setSpecs((prev) => [...prev, newSpec]);
    setNeedsSave(true);
  };

  const handleDelete = (index: number | undefined) => {
    if (index === undefined) return;
    setSpecs((prev) => prev.filter((x) => x.index !== index));
    setNeedsSave(true);
  };

  const handleBlur = (spec: InternalShiftSpec) => {
    setSpecs((prev) => {
      const existing = prev.find((x) => x.index === spec.index);
      if (JSON.stringify(existing) === JSON.stringify(spec)) return prev;

      setNeedsSave(true);
      return prev.map((x) => (x.index === spec.index ? spec : x));
    });
  };

  return (
    <div className="relative min-h-96">
      <ul className="mx-auto max-w-[600px] flex-col space-y-1 p-1">
        <li className="grid grid-cols-[1fr_70px_70px_70px_40px] gap-3 text-sm">
          <div></div>
          <div className="pl-1">Start</div>
          <div className="col-span-2 grid grid-cols-2 gap-3 rounded-md bg-slate-200">
            <div className="pl-1">Dauer</div>
            <div className="pl-1">Ende</div>
          </div>

          <div></div>
        </li>
        {specs.map((spec, i) => (
          <EditableShiftSpec
            key={spec.index}
            patterns={patterns}
            spec={spec}
            onDeleted={handleDelete.bind(this)}
            onBlur={handleBlur.bind(this)}
          />
        ))}
      </ul>
      <div className="mx-auto max-w-[600px] pb-20 pr-8 pt-2 text-right">
        <LinkButton onClick={handleAdd.bind(this)}>
          + hinzuf&uuml;gen
        </LinkButton>
      </div>
      <div className="absolute bottom-0 flex w-full justify-end gap-2 p-2">
        <GhostButton onClick={props.onCancel?.bind(this)}>
          Abbrechen
        </GhostButton>
        <Button
          disabled={!needsSave}
          color="accent"
          onClick={props.onSave?.bind(this, specs)}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};
