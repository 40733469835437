import React, { useContext, SetStateAction, ReactNode } from 'react';
import { Drawer } from './drawer';
import { DrawerNavItem } from './drawer-nav';

export interface DrawerProviderProps {
  title: string;
  displayName: string;
  brand: 'my' | 'kidsmgr';
  navigation: DrawerNavItem[];
  children: React.ReactNode;
}

export interface IDrawer {
  configure(
    title: string | string[],
    toolbar?: React.ReactNode,
    back?: string
  ): void;
}

export class DrawerControl implements IDrawer {
  private _setTitle?: (value: SetStateAction<string>) => void;
  private _setLines?: (value: SetStateAction<string[]>) => void;
  private _setToolbar?: (value: SetStateAction<ReactNode>) => void;
  private _setBack?: (value: SetStateAction<string | undefined>) => void;

  configure(
    title: string | string[],
    toolbar?: React.ReactNode,
    back?: string
  ) {
    //push call to the next event loop with setTimeout
    const [line1, ...lines] = Array.isArray(title) ? title : [title];
    setTimeout(() => {
      this._setTitle?.(line1);
      this._setLines?.(lines);
      this._setToolbar?.(toolbar);
      this._setBack?.(back);
    }, 0);
  }

  bind(
    setTitle: (value: SetStateAction<string>) => void,
    setLines: (value: SetStateAction<string[]>) => void,
    setToolbar: (value: SetStateAction<ReactNode>) => void,
    setBack: (value: SetStateAction<string | undefined>) => void
  ) {
    this._setTitle = setTitle;
    this._setLines = setLines;
    this._setToolbar = setToolbar;
    this._setBack = setBack;
  }
}

export const DrawerContext = React.createContext(
  new DrawerControl() as IDrawer
);

export const DrawerProvider = (props: DrawerProviderProps) => {
  const [title, setTitle] = React.useState<string>(props.title);
  const [lines, setLines] = React.useState<string[]>([]);
  const [toolbar, setToolbar] = React.useState<React.ReactNode | undefined>();
  const [back, setBack] = React.useState<string>();

  (useContext(DrawerContext) as DrawerControl).bind(
    setTitle,
    setLines,
    setToolbar,
    setBack
  );

  return (
    <Drawer
      displayName={props.displayName}
      title={title}
      lines={lines}
      brand={props.brand}
      navigation={props.navigation}
      toolbar={toolbar}
      back={back}
    >
      {props.children}
    </Drawer>
  );
};
