import { LogoMy } from './logo-my';
import { LogoKids } from './logo-kids';

/**
 * The classic KidsManager logo in various formats.
 * Generate the full logo or just the icon, in full color, black or white.
 * @param props
 * @returns
 */
export const Logo = (props: {
  brand?: 'kidsmgr' | 'my';
  format?: 'icon' | 'full';
  size?: number;
}) => {
  const size = props.size || 32;

  if (props.brand === 'my') {
    return props.format === 'icon' ? (
      <LogoMy size={size} />
    ) : (
      <span
        className="inline-flex items-center"
        style={{ color: '#1B333E', fontSize: `${size}px` }}
      >
        <LogoMy size={size} />
        <span className="pr-1 italic">my</span>
        <span className="font-bold">Kids</span>
        <span className="font-extralight">Manager</span>
      </span>
    );
  } else {
    return props.format === 'icon' ? (
      <LogoKids size={size} />
    ) : (
      <div
        className="inline-flex items-center py-2"
        style={{ fontSize: `${size}px`, color: '#271A0C' }}
      >
        <LogoKids size={size} />
        <span className="font-bold">Kids</span>
        <span className="font-extralight">Manager</span>
      </div>
    );
  }
};
