import {
  Button,
  GhostButton,
  IconButton,
  Input,
  ValuePercent
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IDirectoryUser,
  IMembership,
  IMembershipBase
} from '@kidsmanager/util-models';

export interface TeamEditorProps {
  month: string;
  group: string;
  team: IMembership[];
  onCancel?: () => void;
  onSave?: (team: IMembershipBase[]) => void;
}

export const TeamEditor = (props: TeamEditorProps) => {
  const client = useContext(ClientBackendContext);
  const [current, setCurrent] = useState([...props.team]);
  const [staff, setStaff] = useState<IDirectoryUser[]>([]);
  const [options, setOptions] = useState<IDirectoryUser[]>([]);
  const [selected, setSelected] = useState('');
  const [pattern, setPattern] = useState('');
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    client.directory.users().then(setStaff);
  }, [client]);

  const handleSearch = (query: string) => {
    setPattern(query);
    query = query.trim().toLowerCase();
    if (!query) {
      setOptions([]);
    } else {
      setOptions(
        staff.filter(
          (u) =>
            `${u.firstname} ${u.lastname}`.toLowerCase().includes(query) &&
            !current.some((m) => m.id === u.id)
        )
      );
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelected((prev) => {
          const index = options.findIndex((u) => u.id === prev);
          return options[index + 1]?.id || options[0]?.id || '';
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelected((prev) => {
          const index = options.findIndex((u) => u.id === prev);
          return (
            options[index - 1]?.id || options[options.length - 1]?.id || ''
          );
        });
        break;
      case 'Enter':
        e.preventDefault();
        await handleUserSelected(
          options.find((u) => u.id === selected) || options[0]
        );
        break;
      case 'Escape':
        e.preventDefault();
        setOptions([]);
        break;
    }
  };

  const handleDelete = (item: unknown) => {
    setCurrent((prev) => prev.filter((u) => u !== item));
    setDirty(true);
  };

  const handleUserSelected = async (user: IDirectoryUser) => {
    //Add user name and id to team, and replace as soon as the server responds
    setCurrent((prev) => [
      ...prev,
      { ...user, maxHours: 0, hours: 0, fixed: false, dutyRosters: '' }
    ]);
    setOptions([]);
    setSelected('');
    setPattern('');
    setDirty(true);

    client.roster.team
      .member(props.group, user.id, props.month)
      .then((member) => {
        setCurrent((prev) => {
          const index = prev.findIndex((m) => m.id === member.id);
          return [...prev.slice(0, index), member, ...prev.slice(index + 1)];
        });
      });
  };

  const handleSave = () => {
    props.onSave?.(
      current.map((m) => ({ id: m.id, hours: m.hours, fixed: m.fixed }))
    );
  };

  const handleChangeHours = (member: IMembership, value: number) => {
    member.hours = Math.min(value, member.maxHours);
    setCurrent([...current]);
    setDirty(true);
  };

  return (
    <div className="relative min-h-[400px] px-10 py-1 pb-16">
      <div className="mx-4 mb-8 flex gap-2">
        <div className="relative flex-1">
          <Input
            placeholder="Teammitglieder suchen..."
            value={pattern}
            onChange={handleSearch.bind(this)}
            onKeyDown={handleKeyDown.bind(this)}
          />
          {options.length > 0 && (
            <ul className="absolute inset-x-0 top-10 z-10 bg-white py-2 shadow-lg">
              {options.map((user) => (
                <li
                  key={user.id}
                  onClick={handleUserSelected.bind(this, user)}
                  className={`cursor-pointer px-4 leading-8 hover:bg-neutral-300 ${selected === user.id && 'bg-neutral-200'}`}
                >
                  {user.firstname} {user.lastname}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <ul className="h-96 overflow-scroll rounded-md bg-neutral-50 px-4 py-1">
        {current.map((member) => (
          <li
            key={`${member.id}-${member.hours}`}
            className="flex items-center gap-2 leading-10"
          >
            <span className="flex-1">
              {member.firstname} {member.lastname}
            </span>
            <ValuePercent
              value={member.hours}
              max={member.maxHours}
              onChange={handleChangeHours.bind(this, member)}
            />
            <IconButton onClick={handleDelete.bind(this, member)}>
              delete
            </IconButton>
          </li>
        ))}
      </ul>
      <div className="absolute inset-x-2 bottom-2 mt-8 flex justify-end gap-4">
        <GhostButton onClick={props.onCancel}>Abbrechen</GhostButton>
        <Button
          disabled={!isDirty}
          color="accent"
          onClick={handleSave.bind(this)}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};
