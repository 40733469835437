import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { Checkbox, LinkButton } from '@kidsmanager/ui-core';

export const AdminGroupFeaturesDrive = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const { t } = useTranslation('admin');
  const { selected } = context;

  return (
    <>
      <h1 className="mb-4 text-lg">
        <Link
          className="font-semibold tracking-wide underline"
          to={`/admin/groups/${selected.id}/features`}
        >
          {t('groups.features.title')}
        </Link>
        <span className="mx-2">&gt;</span>
        <span> {t('groups.features.cloud.title')}</span>
      </h1>
      <p className="mb-4 max-w-xl text-sm">
        {t('groups.features.cloud.purpose')}
      </p>
      <div className="min-h-80 max-w-2xl rounded-md border border-black/10 bg-white px-4 py-2">
        <div className="grid grid-cols-[1fr,auto,auto,3rem] gap-x-4">
          <label className="mb-3 text-xs font-bold">
            {t('groups.features.cloud.foldername')}
          </label>
          <label className="mb-3 text-xs font-bold">
            {t('groups.features.cloud.allow_folders')}
          </label>
          <label className="mb-3 text-xs font-bold">
            {t('groups.features.cloud.allow_files')}
          </label>
          <label />
          {/* Folders */}

          <div className="flex items-center gap-1">
            <span className="material-icons">folder</span>
            {t('groups.features.cloud.root')}
          </div>
          <Checkbox disabled />
          <Checkbox disabled />
        </div>
        <div className="ml-6 mt-2">
          <LinkButton disabled>+ {t('common.add')}</LinkButton>
        </div>
      </div>
    </>
  );
};
