import { IShiftSpec } from '@kidsmanager/util-models';
import { InternalShiftSpec } from '../models';
import { nameToColor, symbolize } from '@kidsmanager/util-common';
import { endFromStartAndHours } from './shift-mananger-helpers';

export const KeyHoliday = '-holiday-';
export const KeyUnavailable = '-unavailable-';

export const standardShifts = (): InternalShiftSpec[] => {
  return [
    {
      index: -2,
      id: KeyHoliday,
      groupId: '',
      name: 'Urlaub',
      color: '#e0e0e0',
      symbol: 'U',
      start: '00:00',
      end: '23:59',
      duration: 24,
      infoOnly: true,
      tip: 'Urlaub muss vom Teammitglied hinzugefügt werden und kann hier nicht eingetragen werden.',
      readonly: true,
      created: '2020-01'
    },
    {
      index: -1,
      id: KeyUnavailable,
      groupId: '',
      name: 'Nicht verfügbar',
      color: '#e0e0e0',
      symbol: 'X',
      start: '00:00',
      end: '23:59',
      duration: 24,
      readonly: true,
      created: '2020-01'
    }
  ];
};

export const compileShifts = (
  group: string,
  shifts: IShiftSpec[],
  standard: InternalShiftSpec[]
): InternalShiftSpec[] => {
  const clone: InternalShiftSpec[] = shifts.map((x) => ({
    ...x,
    color: nameToColor(x.name),
    end: endFromStartAndHours(x.start, x.duration)
  }));
  const sorted = sortShifts(group, clone) as InternalShiftSpec[];
  symbolize(sorted, group);
  const toSelect = sorted.find((x) => x.groupId === group);
  if (toSelect) {
    toSelect.selected = true;
  }
  return [...standard, ...sorted];
};

const sortShifts = (group: string, value: InternalShiftSpec[]) => {
  const result: IShiftSpec[] = [];

  type GrouppedShiftLookup = {
    [key: string]: {
      shifts: InternalShiftSpec[];
      hrs: number;
      start: string;
    };
  };

  //group by first letter of name
  const grouped = Object.values(
    value.reduce((acc, shift) => {
      const key = shift.name.charAt(0).toUpperCase();
      if (!acc[key]) {
        acc[key] = { shifts: [shift], hrs: 0, start: shift.start };
      } else {
        acc[key].shifts.push(shift);
      }
      return acc;
    }, {} as GrouppedShiftLookup)
  );

  //calculate max hours and earliest start time
  for (const group of grouped) {
    group.hrs = group.shifts.reduce(
      (max, shift) => Math.max(max, shift.duration),
      0
    );
    group.start = group.shifts.reduce(
      (earliest, x) => (x.start < earliest ? x.start : earliest),
      group.start
    );
  }

  //sort by max hours and earliest start time
  const sortedByMaxHrs = grouped.sort((a, b) => b.hrs - a.hrs);
  for (const group of sortedByMaxHrs) {
    group.shifts.sort((a, b) => (a.start > b.start ? 1 : -1));
    result.push(...group.shifts);
  }

  return result;
};
