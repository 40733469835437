import {
  IRosterPlan,
  IRosterRulesConfig,
  ITeamConfig
} from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DayData, InternalShiftSpec, UserData, TeamMember } from './models';
import {
  RosterUserLabel,
  RosterCursor,
  UnsetCursor,
  RosterDay,
  RosterDayLabel,
  RosterStatusDay,
  RosterStatusUser
} from './components';
import RulesWorker from './helpers/rules-web-worker?worker';
import { updateDayStatus, updateUserStatus } from './helpers/status-updates';
import { buildUsers, expandBy } from './helpers';

const worker = new RulesWorker();

export interface RosterEditorProps {
  year: number;
  month: number;
  publicHolidays: Date[];
  group: string;
  config: ITeamConfig | undefined;
  rulesConfig: IRosterRulesConfig | undefined;
  specs: InternalShiftSpec[];
  members: TeamMember[];
  plans: IRosterPlan[];
  daysAsRows?: boolean;
  groupDict?: Record<string, string>;
  onChange?: () => void;
}

export const RosterEditor = (props: RosterEditorProps) => {
  const {
    year,
    month,
    group,
    config,
    members,
    specs,
    plans,
    rulesConfig,
    publicHolidays
  } = props;

  const table = useRef<HTMLTableElement>(null);
  const row_names = useRef<HTMLTableRowElement>(null);
  const [dates, setDates] = useState<DayData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const [cursor, setCursor] = useState(UnsetCursor);
  const [blocked, setBlocked] = useState<
    { blocked: boolean; notes: string[] }[][]
  >([]);

  const debug = useMemo(() => {
    return localStorage.getItem('persist-debug-plan') === 'true';
  }, []);

  const durations = useMemo(() => {
    const dict: Map<string, number> = new Map();
    specs.forEach((spec) => {
      if ((spec.index || 0) < 0) return;
      dict.set(spec.id, spec.duration);
    });
    return dict;
  }, [specs]);

  useEffect(() => {
    // establish independent of worker.postMessage to avoid problems on first load
    // of page and the blocked status not being shown
    worker.onmessage = ({ data }) => setBlocked(data.blocked);
  }, []);

  useEffect(() => {
    worker.postMessage({
      users,
      rulesConfig,
      plans,
      specs,
      dates,
      publicHolidays
    });
  }, [users, rulesConfig, plans, specs, dates, publicHolidays]);

  useEffect(() => {
    setUsers(buildUsers(members, publicHolidays, year, month));
  }, [members, year, month, publicHolidays]);

  useEffect(() => {
    if (!config || !users.length) {
      return;
    }
    const daysInMonth = new Date(year, month, 0).getDate();
    const data = Array.from({ length: daysInMonth + expandBy * 2 }).map(
      (_, i) => {
        const date = new Date(year, month - 1, i - expandBy + 1);
        return {
          date,
          index: i + 1,
          inMonth: date.getMonth() === month - 1,
          workday: date.getDay() !== 0 && date.getDay() !== 6
        };
      }
    );

    updateDayStatus(group, config, plans, durations, data);
    updateUserStatus(plans, users, durations);
    setDates(data);
  }, [year, month, group, config, plans, users, durations]);

  const handleOnChange = (day: number, userId: string) => {
    if (!config) return;
    updateDayStatus(group, config, plans, durations, dates, day);
    updateUserStatus(plans, users, durations, userId);

    setTimeout(() => setDates([...dates]), 0);
    props.onChange?.();
  };

  const handleMouseOver = (e: React.MouseEvent) => {
    if (!table.current || !row_names.current) {
      return;
    }

    const tableRect = table.current.getBoundingClientRect();
    const cell = e.target as HTMLTableCellElement;
    const { top, left, width, height } = cell.getBoundingClientRect();

    const x = left - tableRect.left + width / 2;
    const y = top - tableRect.top + height / 2;
    const xoffset = 60;
    const yoffset = row_names.current.getBoundingClientRect().height;
    setCursor({ x, y, yoffset, xoffset });
  };

  const getBlocked = (user: number, day: number) => {
    if (!blocked[user]) {
      return { blocked: false, notes: [] };
    }
    return blocked[user][day];
  };

  return (
    <div
      className={`relative mb-10 flex-1 rounded-md ${!props.daysAsRows && 'overflow-x-scroll'}`}
    >
      <RosterCursor {...cursor} />
      {props.daysAsRows && (
        <table ref={table}>
          <thead className="sticky top-5 z-10 bg-white/80 backdrop-blur-md">
            <tr ref={row_names}>
              <th></th>
              {users.map((user) => (
                <th
                  key={user.id}
                  className="relative h-20 text-nowrap font-normal"
                >
                  <RosterUserLabel rotate>{user.name}</RosterUserLabel>
                </th>
              ))}
              <th></th>
            </tr>
            <tr>
              <td></td>
              {users.map((user) => (
                <td key={`status-${user.id}`}>
                  <RosterStatusUser user={user} year={year} month={month} />
                </td>
              ))}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {dates.map((day, i) =>
              day.inMonth || debug ? (
                <tr key={day.index}>
                  <td className={!day.inMonth ? 'bg-sky-50' : undefined}>
                    <RosterDayLabel date={day.date} />
                  </td>
                  {users.map((user, userIndex) => (
                    <td
                      key={`${day.index}-${user.id}`}
                      className="border border-neutral-200/70"
                      onMouseEnter={handleMouseOver.bind(this)}
                    >
                      <RosterDay
                        index={userIndex}
                        group={group}
                        specs={specs}
                        value={plans[userIndex]?.assigned[i]}
                        status={getBlocked(userIndex, i)}
                        groupDict={props.groupDict}
                        user={user}
                        day={day}
                        onChange={handleOnChange.bind(this)}
                      />
                    </td>
                  ))}
                  <td>
                    <RosterStatusDay cover={day.cover} />
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      )}
      {!props.daysAsRows && (
        <table ref={table} className="mr-52">
          <thead>
            <tr ref={row_names}>
              <th colSpan={2} className="sticky left-0 z-10 bg-white" />
              {dates.map((data, day) =>
                data.inMonth || debug ? (
                  <th
                    key={day}
                    className={`h-20 align-bottom font-normal ${!data.inMonth ? 'bg-sky-50' : undefined}`}
                  >
                    <RosterDayLabel date={data.date} asColumn />
                  </th>
                ) : null
              )}
            </tr>
          </thead>
          <tbody>
            {users.map((user, userIndex) => (
              <tr key={user.id}>
                <td className="sticky left-0 z-10 min-w-24 text-ellipsis text-nowrap bg-white font-normal leading-8">
                  <RosterUserLabel>{user.name}</RosterUserLabel>
                </td>
                <td className="sticky left-24 z-10 bg-white">
                  <RosterStatusUser
                    user={user}
                    year={year}
                    month={month}
                    asColumn
                  />
                </td>
                {dates.map((data, day) =>
                  data.inMonth || debug ? (
                    <td
                      key={`${user.id}-${day}`}
                      className="border border-neutral-200/70"
                      onMouseEnter={handleMouseOver.bind(this)}
                    >
                      <RosterDay
                        index={userIndex}
                        group={group}
                        specs={specs}
                        value={plans[userIndex]?.assigned[day]}
                        status={getBlocked(userIndex, day)}
                        groupDict={props.groupDict}
                        user={user}
                        day={data}
                        onChange={handleOnChange.bind(this)}
                        asColumn
                      />
                    </td>
                  ) : null
                )}
              </tr>
            ))}
            <tr>
              <td colSpan={2} className="sticky left-0 z-10 bg-white" />
              {dates.map((data, day) =>
                data.inMonth || debug ? (
                  <td key={day}>
                    <RosterStatusDay cover={data.cover} asColumn />
                  </td>
                ) : null
              )}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
