export const LogoKids = (props: { size?: number }) => {
  const size = props.size || 32;
  return (
    <svg
      width={size + 10}
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(8, 0)">
        <g fill="#442A10" transform="translate(0, 0)">
          <path d="M0 22a3 3 0 1 1 6 0v10h-6v-10Z" />
          <circle cx="3" cy="15" r="3" />
        </g>
        <g fill="#442A10" transform="translate(9, 0)">
          <path d="M0 16a3 3 0 1 1 6 0v16h-6V16Z" />
          <circle cx="3" cy="9" r="3" />
        </g>
        <g opacity=".6" fill="#442A10" transform="translate(18, 0)">
          <path d="M0 13a3 3 0 0 1 6 0v19h-6V13Z" />
          <circle cx="3" cy="6" r="3" />
        </g>
      </g>
    </svg>
  );
};
