import {
  IUser,
  IGroupSummary,
  ROLE_LABELS,
  IRosterTemplate
} from '@kidsmanager/util-models';

export interface FilterDefinition {
  text?: string;
  group?: string;
  role?: string;
  template?: string;
  manager?: string;
  locked?: boolean;
  email: { verified?: boolean; unverified?: boolean; none?: boolean };
  hours?: { from?: number; to?: number };
  holidays?: { from?: number; to?: number };
}

export const emptyFilter = () => ({ email: {} }) as FilterDefinition;

const roleLookup = Array.from(ROLE_LABELS.keys())
  .sort((a, b) => (a > b ? -1 : 1))
  .reduce(
    (prev, id) => {
      prev.push({ id, label: ROLE_LABELS.get(id)?.toLowerCase() || '' });
      return prev;
    },
    [] as { id: string; label: string }[]
  );

const groupRegex = /g(?:ruppe)?=([^\s]+)/i;
const roleRegex = /r(?:olle)?=([^\s]+)/i;
const templateRegex = /d(?:ienstvorlage)?=([^\s]+)/i;
const managerRegex = /v(?:orgesetzeter)?=([^\s]+)/i;
const accessRegex = /z(?:ugang)?=([^\s]+)/i;
const hoursGreaterRegex = /s(?:tunden)?>([^\s]+)/i;
const hoursLessRegex = /s(?:tunden)?<([^\s]+)/i;
const holidaysGreaterRegex = /u(?:rlaub)?>([^\s]+)/i;
const holidaysLessRegex = /u(?:rlaub)?<([^\s]+)/i;
const emailRegex = /e(?:mail)?=([^\s]+)/i;

export class FilterLogic {
  static toExpression(def: FilterDefinition): string {
    let result = '';
    if (def.group) {
      result += `g=${def.group} `;
    }
    if (def.role) {
      result += `r=${def.role} `;
    }
    if (def.template) {
      result += `d=${def.template} `;
    }
    if (def.manager) {
      result += `v=${def.manager} `;
    }
    if (def.locked) {
      result += 'z=gesperrt ';
    }
    if (def.email.none) {
      result += 'e=keine ';
    }
    if (def.email.unverified) {
      result += 'e=nicht ';
    }
    if (def.email.verified) {
      result += 'e=passt ';
    }
    if (def.hours) {
      if (def.hours.from) {
        result += `s>${def.hours.from - 1} `;
      }
      if (def.hours.to) {
        result += `s<${def.hours.to + 1} `;
      }
    }
    if (def.holidays) {
      if (def.holidays.from) {
        result += `u>${def.holidays.from - 1} `;
      }
      if (def.holidays.to) {
        result += `u<${def.holidays.to + 1} `;
      }
    }
    if (def.text) {
      result += def.text;
    }
    return result.trim();
  }

  static fromExpression(value: string): FilterDefinition {
    const definition = emptyFilter();

    value = value.toLowerCase();
    const group = groupRegex.exec(value);
    if (group) {
      value = value.replace(group[0], '');
      definition.group = group[1].trim();
    }
    const role = roleRegex.exec(value);
    if (role) {
      value = value.replace(role[0], '');
      definition.role = role[1].trim();
    }
    const template = templateRegex.exec(value);
    if (template) {
      value = value.replace(template[0], '');
      definition.template = template[1].trim();
    }
    const manager = managerRegex.exec(value);
    if (manager) {
      value = value.replace(manager[0], '');
      definition.manager = manager[1].trim();
    }
    const status = accessRegex.exec(value);
    if (status) {
      value = value.replace(status[0], '');
      definition.locked = status[1].trim() === 'gesperrt';
    }
    const hoursGreater = hoursGreaterRegex.exec(value);
    if (hoursGreater) {
      value = value.replace(hoursGreater[0], '');
      definition.hours = definition.hours || {};
      definition.hours.from = parseInt(hoursGreater[1], 10) + 1;
    }
    const hoursLess = hoursLessRegex.exec(value);
    if (hoursLess) {
      value = value.replace(hoursLess[0], '');
      definition.hours = definition.hours || {};
      definition.hours.to = parseInt(hoursLess[1], 10) - 1;
    }
    const holidaysGreater = holidaysGreaterRegex.exec(value);
    if (holidaysGreater) {
      value = value.replace(holidaysGreater[0], '');
      definition.holidays = definition.holidays || {};
      definition.holidays.from = parseInt(holidaysGreater[1], 10) + 1;
    }
    const holidaysLess = holidaysLessRegex.exec(value);
    if (holidaysLess) {
      value = value.replace(holidaysLess[0], '');
      definition.holidays = definition.holidays || {};
      definition.holidays.to = parseInt(holidaysLess[1], 10) - 1;
    }

    let email = emailRegex.exec(value);
    while (email) {
      if (email) {
        value = value.replace(email[0], '');
        if (email[1] === 'keine' || email[1] === 'none') {
          definition.email.none = true;
        } else if (email[1] === 'nicht' || email[1] === 'not') {
          definition.email.unverified = true;
        } else if (email[1] === 'passt' || email[1] === 'good') {
          definition.email.verified = true;
        }
      }
      email = emailRegex.exec(value);
    }

    const text = value.replace(/[<>]/g, ' ').trim();
    if (text.length > 0) {
      definition.text = text;
    }
    return definition;
  }

  apply(
    groups: IGroupSummary[],
    templates: IRosterTemplate[],
    active: IUser[],
    locked: IUser[],
    def: FilterDefinition
  ): IUser[] {
    let filtered: IUser[] = def.locked ? [...locked] : [...active];
    if (def.group) {
      const pattern = def.group.toLowerCase();
      const test = groups.filter((g) => g.name.toLowerCase().includes(pattern));
      filtered = filtered.filter((user) =>
        test.some((g) => user.groupIds?.includes(g.id))
      );
    }

    if (def.role) {
      const pattern = def.role.toLowerCase();
      const test = roleLookup.find((r) => r.label.startsWith(pattern))?.id;
      filtered = filtered.filter((user) => user.roles?.some((r) => r === test));
    }

    if (def.template) {
      const pattern = def.template.toLowerCase();
      if (pattern === 'ohne') {
        filtered = filtered.filter((user) => !user.dutyRosters);
      } else {
        const test = templates.filter((t) =>
          t.name.toLowerCase().includes(pattern)
        );
        filtered = filtered.filter((user) =>
          test.some((t) => user.dutyRosters?.includes(t.id))
        );
      }
    }

    if (def.manager) {
      const pattern = def.manager.toLowerCase();
      if (pattern === 'ohne') {
        filtered = filtered.filter((user) => !user.managerId);
      } else {
        const test = active.filter(
          (u) =>
            u.roles?.some((r) => r === 'leader' || r === 'manager') &&
            (u.lastName?.toLowerCase().includes(pattern) ||
              u.firstName?.toLowerCase().includes(pattern))
        );
        filtered = filtered.filter((user) =>
          test.some((u) => user.managerId === u.userId)
        );
      }
    }

    if (def.hours) {
      const { from, to } = def.hours;
      if (from) {
        filtered = filtered.filter((user) => (user.hoursPerWeek || 0) >= from);
      }
      if (to) {
        filtered = filtered.filter((user) => (user.hoursPerWeek || 0) <= to);
      }
    }

    if (def.holidays) {
      const { from, to } = def.holidays;
      if (from) {
        filtered = filtered.filter(
          (user) => (user.holidayAllowance || 0) >= from
        );
      }
      if (to) {
        filtered = filtered.filter(
          (user) => (user.holidayAllowance || 0) <= to
        );
      }
    }

    if (def.email.none && def.email.unverified) {
      filtered = filtered.filter((user) => !user.verifiedEmail);
    } else if (def.email.verified) {
      filtered = filtered.filter((user) => user.verifiedEmail);
    } else if (def.email.none) {
      filtered = filtered.filter((user) => !user.email);
    } else if (def.email.unverified) {
      filtered = filtered.filter((user) => user.email && !user.verifiedEmail);
    }

    if (def.text && def.text.length > 1) {
      // start after the first character to give chance to specify advanced search g= or r=
      if (!def.text.includes('=')) {
        const pattern = def.text.toLowerCase();
        filtered = filtered.filter((user) => {
          return (
            user.firstName?.toLowerCase().includes(pattern) ||
            user.lastName?.toLowerCase().includes(pattern)
          );
        });
      }
    }
    return filtered;
  }
}
