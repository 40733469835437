import { useLocation } from 'react-router-dom';
import { HeaderMenuContainer, HeaderMenuItem } from './header-menu';
import { IconGroup } from './icons';
import { useMemo } from 'react';

export interface HeaderGroupProps {
  groups: { title: string; href: string; id?: string }[];
  selected?: string;
}

export const HeaderGroupSelector = (props: HeaderGroupProps) => {
  const location = useLocation();
  const referrer = useMemo(() => location.pathname, [location.pathname]);
  return (
    <div className="border-r-silver-400 text-hdr2 group relative inline-flex h-7 cursor-pointer items-center border-r pl-1 pr-[5px]">
      <span className="outline-focus outline-2 outline-offset-2" tabIndex={0}>
        <IconGroup />
      </span>
      <HeaderMenuContainer position="left" top={25}>
        {props.groups.map((group) => (
          <HeaderMenuItem
            key={group.title}
            title={group.title}
            href={`${group.href}?referrer=${referrer}`}
            selected={group.id === props.selected}
          />
        ))}
      </HeaderMenuContainer>
    </div>
  );
};
