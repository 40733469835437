import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ContextMenuButton,
  Dialog,
  DialogContext,
  LoadSpinner,
  MonthPicker
} from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  IRosterPlan,
  IMembershipBase,
  IReleasedPlan,
  IRosterRulesConfig,
  ITeamConfig,
  IMembership
} from '@kidsmanager/util-models';
import { RosterShiftManager } from './roster-shift-manager';
import { RosterEditor } from './roster-editor';
import { InternalShiftSpec, TeamMember } from './models';
import { RosterVersioner, VersionState } from './roster-versioner';
import {
  PlanCompiler,
  compileShifts,
  compileTeam,
  compileStatus,
  standardShifts
} from './helpers';
import { TeamEditor } from './components/team-editor';

export const Roster = () => {
  const [daysAsRows, setDaysAsRows] = useState(
    localStorage.getItem('roster-days-as-rows') !== 'false'
  );

  const [members, setMembers] = useState<IMembership[]>([]);
  const [config, setConfig] = useState<ITeamConfig>();
  const [rules, setRules] = useState<IRosterRulesConfig>();
  const [specs, setSpecs] = useState<InternalShiftSpec[]>([]);
  const [team, setTeam] = useState<TeamMember[]>([]);
  const [versionState, setVersionState] = useState<VersionState>('empty');
  const [releases, setReleases] = useState<IReleasedPlan[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<Date[]>([]);
  const [loading, setLoading] = useState(true);
  const [groupDict, setGroupDict] = useState<Record<string, string>>({});

  const [compiler] = useState<PlanCompiler>(new PlanCompiler());
  const [plans, setPlans] = useState<IRosterPlan[]>([]);

  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const { group, ...params } = useParams<{ group: string; month: string }>();
  const navigate = useNavigate();

  const { year, month } = useMemo(() => {
    if (!params.month) {
      const today = new Date();
      return { year: today.getFullYear(), month: today.getMonth() + 1 };
    } else {
      const [year, month] = params.month.split('-').map(Number);
      return { year, month };
    }
  }, [params.month]);

  useEffect(() => {
    if (!client || !group) return;
    const fetchData = async () => {
      const members = await client.roster.team.list(group, year, month);
      const [holidays, publicHols, rules, config, stats, draft, releases] =
        await Promise.all([
          client.holiday.bookingsForUsers(year, month, members),
          client.holiday.public(year),
          client.roster.config.rules(),
          client.roster.team.config(group),
          client.roster.team.ytd(year, month, members),
          client.roster.plan.draft(year, month, members),
          client.roster.plan.releases(group, year, month)
        ]);

      const compiled = compiler
        .establishContext(holidays, publicHols)
        .run(draft, year, month);
      setRules(rules);
      setConfig(config);
      setPublicHolidays(publicHols);
      setTeam(compileTeam(members, stats, compiler.memberHolidays()));
      setVersionState(compileStatus(compiled.plans, releases));
      setPlans(compiled.plans);
      setReleases(releases);
      setLoading(false);

      client.roster.shifts
        .list(year, month, group, ...compiled.groups)
        .then((shifts) =>
          setSpecs(compileShifts(group, shifts, standardShifts()))
        );

      const otherGroups = compiled.groups.filter((g) => g !== group);
      if (otherGroups.length > 0) {
        client.directory.groups().then((dict) => {
          setGroupDict(
            otherGroups.reduce(
              (lookup, id) => {
                lookup[id] = dict.find((x) => x.id === id)?.name ?? '';
                return lookup;
              },
              {} as Record<string, string>
            )
          );
        });
      }
    };
    fetchData();
  }, [client, members, group, year, month, compiler]);

  const handleTeamChange = async (team: IMembershipBase[]) => {
    if (!group) return;
    client.roster.team.update(group, year, month, team).then(setMembers);
    dialog.close();
  };

  const handleContextMenu = (key: string) => {
    switch (key) {
      case 'rotate':
        setDaysAsRows((prev) => {
          localStorage.setItem('roster-days-as-rows', (!prev).toString());
          return !prev;
        });
        break;
      case 'team':
        dialog.open(
          <Dialog
            title="Team"
            width="w-[600px]"
            close={dialog.close.bind(dialog)}
          >
            <TeamEditor
              month={`${year}-${month}`}
              group={group || ''}
              team={team}
              onCancel={() => dialog.close()}
              onSave={handleTeamChange.bind(this)}
            />
          </Dialog>
        );
        break;
    }
  };

  const handleMonthChange = (year: number, month: number) => {
    navigate(
      `/team/${group}/roster/${year}-${month.toString().padStart(2, '0')}`
    );
  };

  const handleSelected = (spec: InternalShiftSpec) => {
    setSpecs((prev) =>
      prev.map((s) => {
        s.selected = s.id === spec.id ? true : false;
        return s;
      })
    );
  };

  const handleSpecsChange = (value: InternalShiftSpec[]) => {
    if (!group) return;
    setSpecs(compileShifts(group, value, standardShifts()));
    const update = compiler.update(plans, value);
    if (update.isDirty) {
      setPlans(update.plans);
      setVersionState('unsaved');
    }
  };

  const handleCancel = async () => {
    if (!group) {
      setVersionState('empty');
    } else {
      const draft = await client.roster.plan.draft(year, month, team);
      const compiled = compiler.run(draft, year, month);
      setPlans(compiled.plans);
      setVersionState(compileStatus(compiled.plans, releases));
    }
  };

  const handleSave = async () => {
    await client.roster.plan.update(year, month, PlanCompiler.slice(plans));
    setVersionState('saved');
  };

  const handleOpen = (version: string) => {
    console.log('open', version);
  };

  const handleRelease = async () => {
    if (!group) return;
    const ids = team.map((m) => m.id);
    const release = await client.roster.plan.release(group, year, month, ids);
    setReleases((prev) => [release, ...prev]);
    setVersionState('released');
  };

  const handleUnpublish = async (version: string) => {
    if (!group) return;

    const ids = team.map((m) => m.id);
    const unpublished = await client.roster.plan.unpublish(
      group,
      year,
      month,
      version,
      ids
    );
    setReleases((prev) =>
      prev.filter((r) => r.version !== unpublished.version)
    );
    setVersionState('saved');
  };

  const handleDiscard = async (version: string) => {
    if (!group) return;

    const ids = team.map((m) => m.id);
    const deleted = await client.roster.plan.discard(
      group,
      year,
      month,
      version,
      ids
    );
    if (version === 'draft') {
      handleCancel();
    } else {
      setReleases((prev) => prev.filter((r) => r.version !== deleted.version));
      setVersionState('saved');
    }
  };

  return (
    group && (
      <div className="relative flex gap-4 p-4">
        <div className="absolute -top-9 right-2">
          <ContextMenuButton
            color="ghost"
            options={[
              { key: 'rotate', label: 'Plan drehen' },
              { key: 'team', label: 'Team verwalten...' }
            ]}
            onChange={handleContextMenu.bind(this)}
          />
        </div>
        <div className="min-w-52 md:min-w-64">
          <div className="sticky top-5 z-10 pt-5">
            <MonthPicker
              year={year}
              month={month}
              onChange={handleMonthChange.bind(this)}
            />
            <RosterShiftManager
              group={group}
              year={year}
              month={month}
              specs={specs}
              onSelected={handleSelected.bind(this)}
              onChange={handleSpecsChange.bind(this)}
            />
            <RosterVersioner
              year={year}
              month={month}
              state={versionState}
              releases={releases}
              onRelease={handleRelease.bind(this)}
              onUnpublish={handleUnpublish.bind(this)}
              onDiscard={handleDiscard.bind(this)}
              onOpen={handleOpen.bind(this)}
              onSave={handleSave.bind(this)}
              onCancel={handleCancel.bind(this)}
            />
          </div>
        </div>
        {loading ? (
          <div className="relative ml-10 mt-6 text-black/50">
            <LoadSpinner show />
          </div>
        ) : (
          <RosterEditor
            year={year}
            month={month}
            group={group}
            groupDict={groupDict}
            publicHolidays={publicHolidays}
            config={config}
            rulesConfig={rules}
            specs={specs}
            members={team}
            plans={plans}
            daysAsRows={daysAsRows}
            onChange={() => setVersionState('unsaved')}
          />
        )}
      </div>
    )
  );
};
