import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { useEffect, useState } from 'react';
import { Checkbox, Input } from '@kidsmanager/ui-core';

export const AdminGroupAdvanced = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const [headername, setHeadername] = useState<string>('');

  const { t } = useTranslation('admin');
  const i18nPrefix = 'groups.advanced';

  const { selected } = context;

  useEffect(() => {
    if (!selected) {
      return;
    }
    setHeadername(selected.advanced.headername || '');
  }, [selected]);

  const handleNamechange = (value: string) => {
    setHeadername(value);
    selected.advanced.headername = value;
    context.onChange?.();
  };
  const handleVirtualChange = (value: boolean) => {
    selected.advanced.virtual = value;
    context.onChange?.();
  };

  return (
    selected && (
      <div key={selected.id} className="flex flex-col gap-4">
        <Input
          autocomplete="off"
          label={t(`${i18nPrefix}.headername.label`)}
          value={headername}
          placeholder="Team"
          onChange={handleNamechange.bind(this)}
        />
        <div className="mt-4 flex max-w-xl items-start gap-2">
          <Checkbox
            checked={selected.advanced.virtual}
            onChange={handleVirtualChange.bind(this)}
          />
          <div className="min-h-24 flex-1">
            <h4 className="mb-1 font-medium">
              {t(`${i18nPrefix}.virtual.label`)}
            </h4>
            <p className="text-sm">{t(`${i18nPrefix}.virtual.description`)}</p>
          </div>
        </div>
      </div>
    )
  );
};
