import { useEffect, useRef, useState } from 'react';
import { InternalShiftSpec } from '../models';

export interface RosterInfoPopupProps {
  shift: InternalShiftSpec;
  shifts?: { id: string; groupId?: string }[];
  groupDict?: Record<string, string>;
  specs: InternalShiftSpec[];
  onClose?: () => void;
}

export const RosterInfoPopup = (props: RosterInfoPopupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [groupName, setGroupName] = useState<string>();

  useEffect(() => {
    const groupId =
      props.shifts?.find((x) => x.id === props.shift.id)?.groupId || '';
    setGroupName(props.groupDict?.[groupId]);
  }, [props.shift, props.shifts, props.groupDict]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.parentNode?.contains(e.target as Node)) {
        props.onClose?.();
      }
    };
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.onClose?.();
      }
    };
    setTimeout(() => {
      window.addEventListener('click', handleClick);
      window.addEventListener('keydown', handleEscape);
    }, 100);
    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [props]);

  return (
    <div
      ref={ref}
      className="shadow-hdr w-screen max-w-xs bg-white p-6"
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="text-wrap">
        <span className="font-bold">{groupName}</span>
        <span className="pl-2 font-medium text-black/60">
          {props.shift.name}
        </span>
      </h2>
      <div className="grid grid-cols-2 px-4 pt-2 text-sm text-black/60">
        <div>
          <label className="pr-1">Von</label>
          <span>{props.shift.start}</span>
        </div>
        <div>
          <label className="pr-1">Dauer</label>
          <span>{props.shift.duration.toFixed(1)}</span>
        </div>
      </div>
    </div>
  );
};
