export enum Modules {
  Invoicing = 1,
  CashJournal = 2,
  Evaluation = 4,
  Calendar = 8,
  Notes = 16,
  Planning = 32,
  Training = 64,
  Medication = 128,
  Timesheets = 256,
  MileageLog = 512,
  DutyRoster = 1024,
  Google = 2048
}

export const hasModule = (value: number, moduleToCheck: Modules): boolean => {
  return (value & moduleToCheck) === moduleToCheck;
};
