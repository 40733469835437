import '@kidsmanager/util-extensions';
import {
  IRosterDraftSet,
  IRosterPlan,
  IShiftSpec,
  IUserBookings
} from '@kidsmanager/util-models';
import { KeyHoliday, KeyUnavailable } from './compiler-shift';
import { HolidayChecker } from './holiday-checker';

export const expandBy = 7;

type CompiledPlans = { plans: IRosterPlan[]; groups: string[] };

export class PlanCompiler {
  private holidays: IUserBookings[] = [];
  private publicHols: Date[] = [];
  private holidayStats: { id: string; days: number }[] = [];

  establishContext(
    holidays: IUserBookings[],
    publicHols: Date[]
  ): PlanCompiler {
    this.holidays = holidays;
    this.publicHols = publicHols;
    return this;
  }

  memberHolidays() {
    return this.holidayStats;
  }

  run(draft: IRosterDraftSet, year: number, month: number): CompiledPlans {
    this.holidayStats = [];
    const plans = structuredClone(draft.current);
    const groupsSet = new Set<string>();
    for (const member of plans) {
      const days = this.addHolidayFor(member, year, month);
      this.holidayStats.push({ id: member.id, days });
      this.expandFor(draft, member);
      member.assigned.forEach((a) =>
        a.shifts.forEach((s) => groupsSet.add(s.groupId ?? ''))
      );
    }
    const groups = Array.from(groupsSet).filter((g) => g !== '');
    return { plans, groups };
  }

  /** Prefixes 7 days of prev and appends 7 days of next onto month
   * @why this is required so be can check week stats at the boundaries of the month
   */
  expandFor(draft: IRosterDraftSet, plan: IRosterPlan) {
    const prev = draft.prev.find((m) => m.id === plan.id);
    const next = draft.next.find((m) => m.id === plan.id);
    if (!prev || !next) return;

    const lastSevenDays = prev.assigned.slice(prev.assigned.length - expandBy);
    const nextSevenDays = next.assigned.slice(0, expandBy);
    plan.assigned.unshift(...lastSevenDays);
    plan.assigned.push(...nextSevenDays);
  }

  addHolidayFor(plan: IRosterPlan, year: number, month: number): number {
    let daysAdded = 0;
    const match = this.holidays.find((b) => b.id === plan.id);

    if (!match || match.bookings.length === 0) return daysAdded;

    const lastDay = new Date(year, month, 0).getDate();
    for (const booking of match.bookings) {
      const { start, end } = HolidayChecker.parse(booking, month, lastDay);
      for (let i = start; i <= end; i++) {
        if (HolidayChecker.isWorkday(year, month, i + 1, this.publicHols)) {
          daysAdded += 1;
        }
        plan.assigned[i].shifts.push({ id: KeyHoliday });
      }
    }
    return daysAdded;
  }

  update(
    plans: IRosterPlan[],
    specs: IShiftSpec[]
  ): { plans: IRosterPlan[]; isDirty: boolean } {
    let isDirty = false;
    const specIds = [...specs.map((s) => s.id), KeyUnavailable, KeyHoliday];
    for (const plan of plans) {
      for (const assignment of plan.assigned) {
        const hasRemoval = !!assignment.shifts.find(
          (s) => !specIds.includes(s.id)
        );
        if (hasRemoval) {
          assignment.shifts = assignment.shifts.filter((s) =>
            specIds.includes(s.id)
          );
          isDirty = true;
        }
      }
    }
    return { plans, isDirty };
  }

  /**
   * Extracts the original plans from the middle of the expanded plans
   */
  static slice(plans: IRosterPlan[]): IRosterPlan[] {
    return plans.map((m) => ({
      ...m,
      assigned: m.assigned.slice(expandBy, m.assigned.length - expandBy * 2)
    }));
  }
}
