import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const roots = ['/team'];

export const FeatureActiveGroup = () => {
  const navigate = useNavigate();
  const client = useContext(ClientBackendContext);
  const [urlSearchParams] = useSearchParams();
  const { group } = useParams<{ group: string }>();
  const referrer = urlSearchParams.get('referrer');

  useEffect(() => {
    client.settings.accessMap(group).then(() => {
      for (const root of roots) {
        if (!referrer?.startsWith(root)) {
          continue;
        }

        const afterGroup = referrer
          .slice(root.length + 1)
          .split('/')
          .slice(1);
        const newUrl = [root, group, ...afterGroup].join('/');
        navigate(newUrl);
        return;
      }
      navigate(referrer ?? '/');
    });
  }, [group, referrer, navigate, client]);

  return (
    <div className="p-10">
      <h1>Switching groups...</h1>
    </div>
  );
};
