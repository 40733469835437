export const symbolize = (
  items: { name: string; groupId: string; symbol?: string; index?: number }[],
  group?: string
) => {
  const totals: { [key: string]: number } = {};

  items.forEach((shift) => {
    if (group && shift.groupId !== group) return;
    const firstChar = shift.name.at(0)?.toUpperCase() || '';
    totals[firstChar] = (totals[firstChar] || 0) + 1;
  });

  const counts: { [key: string]: number } = {};
  items.forEach((shift) => {
    const firstChar = shift.name.at(0)?.toUpperCase() || '';
    if (group && shift.groupId === group && totals[firstChar] > 1) {
      counts[firstChar] = (counts[firstChar] || 0) + 1;
      shift.symbol = `${firstChar}${counts[firstChar]}`;
    } else {
      shift.symbol = firstChar;
    }
  });
};
