import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { OutletContextAdminGroups } from './outlet-context-admin-groups';
import { Fragment, useContext, useEffect, useId, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { ITemplateSummary } from '@kidsmanager/util-models';
import { Checkbox } from '@kidsmanager/ui-core';

export const AdminGroupFeaturesMedication = () => {
  const context = useOutletContext<OutletContextAdminGroups>();
  const client = useContext(ClientBackendContext);
  const { t } = useTranslation('admin');
  const [templateGroups, setTemplateGroups] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Map<string, ITemplateSummary[]>>(
    new Map()
  );
  const [assigned, setAssigned] = useState<string[]>([]);
  const { selected } = context;

  useEffect(() => {
    client.admin.template.summary().then((x) => {
      const map = new Map<string, ITemplateSummary[]>();
      x.forEach((y) => {
        if (!map.has(y.groupName)) {
          map.set(y.groupName, []);
        }
        map.get(y.groupName)?.push(y);
      });
      for (const set of map.values()) {
        set.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
      setTemplates(map);
      setTemplateGroups(
        Array.from(map.keys()).sort((a, b) => (a > b ? 1 : -1))
      );
      setAssigned(selected.features.settings?.medication.templates || []);
    });
  }, [client, selected]);

  const handleChange = (id: string, value: boolean) => {
    setAssigned((prev) => {
      if (!selected.features.settings) {
        return prev;
      }
      const update = value ? [...prev, id] : prev.filter((x) => x !== id);
      selected.features.settings.medication.templates = update;
      context.onChange?.();
      return update;
    });
  };

  return (
    <>
      <h1 className="mb-4 text-lg">
        <Link
          className="font-semibold tracking-wide underline"
          to={`/admin/groups/${selected.id}/features`}
        >
          {t('groups.features.title')}
        </Link>
        <span className="mx-2">&gt;</span>
        <span> {t('groups.features.medication.title')}</span>
      </h1>
      <p className="mb-4 max-w-xl text-sm">
        {t('groups.features.medication.purpose')}
      </p>
      <div className="ml-4">
        {templateGroups.map((group) => (
          <Fragment key={group}>
            <h2>{group}</h2>
            <ul className="ml-6 max-w-sm">
              {templates.get(group)?.map(({ id, name }) => (
                <li className="mb-1 flex" key={id}>
                  <label htmlFor={`template-${id}`} className="flex-1">
                    {name}
                  </label>
                  <Checkbox
                    id={`template-${id}`}
                    checked={assigned.includes(id)}
                    onChange={handleChange.bind(this, id)}
                  />
                </li>
              ))}
            </ul>
          </Fragment>
        ))}
      </div>
    </>
  );
};
