import { IUserAllowance } from '@kidsmanager/util-models';

export interface IHoursForMonth {
  /** sum of hours for the month */
  hoursPerDay: number;
  hoursPerWeek: number;
  hoursPerMonth: number;
  /* working days in month */
  days: number;
  /* days that the user was working in the month */
  present: number;
  /* hours expected if the user was to work all working days */
  default: number;
  raw: IUserAllowance[];
}

export const hoursForMonth = (
  values: IUserAllowance[],
  year: number,
  month: number
): IHoursForMonth => {
  if (month < 1 || month > 12) {
    throw new Error('Invalid month');
  }
  const result: IHoursForMonth = {
    hoursPerWeek: 0,
    hoursPerDay: 0,
    hoursPerMonth: 0,
    days: 0,
    present: 0,
    default: 0,
    raw: []
  };
  if (values.length === 0) {
    return result;
  }

  const daysInMonth = Array.from(
    { length: new Date(year, month, 0).getDate() },
    (_, i) => i + 1
  );

  for (const day of daysInMonth) {
    const date = new Date(year, month - 1, day);
    if (date.getDay() === 0 || date.getDay() === 6) {
      continue;
    }
    const dom = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const matches = values.filter(
      (x) => x.from <= dom && (!x.to || x.to >= dom)
    );
    if (matches.length > 0) {
      const hours = matches.reduce((acc, x) => acc + x.value, 0) / 5.0;
      result.hoursPerMonth += hours;
      result.default += hours;
    } else {
      //project backwards if employee start month
      const [startYear, startMonth] = values[0].from.split('-').map(Number);
      result.default +=
        startYear === year && startMonth === month ? values[0].value / 5.0 : 0;
    }
    result.days += 1;
  }

  result.hoursPerDay = result.hoursPerMonth / result.days;
  result.hoursPerWeek = result.hoursPerDay * 5;
  result.default = result.default / result.days;
  result.raw = values;
  return result;
};
