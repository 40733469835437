import { FormEvent, useContext, useEffect, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useSearchParams } from 'react-router-dom';
import { Button, Input, Logo, Progress } from '@kidsmanager/ui-core';
import { UserNotFoundException } from '@kidsmanager/util-common';

type SsoState =
  | 'check'
  | 'link'
  | 'unknown-user'
  | 'single-user'
  | 'wait'
  | 'finish';

export interface SsoLoginMessage {
  success: boolean;
  tenant: string;
  token: string;
  refreshToken: string;
  singleUser: boolean;
}

export const FeatureGoogleCallback = (props: { brand: 'kidsmgr' | 'my' }) => {
  const client = useContext(ClientBackendContext);
  const [searchParams] = useSearchParams();
  const [tenant, setTenant] = useState<string | null>(null);
  const [state, setState] = useState<SsoState>('check');
  const [googleEmail, setGoogleEmail] = useState('');
  const [singleUser, setSingleUser] = useState(
    localStorage.getItem('sso-single-user') === 'true'
  );
  const [usernameWithoutTenant, setusernameWithoutTenant] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!tenant || state !== 'finish') {
      return;
    }
    window.opener.postMessage({
      success: true,
      tenant,
      token: client.auth.token(),
      refreshToken: client.auth.refreshToken(),
      singleUser
    } as SsoLoginMessage);
    window.close();
  }, [tenant, state, client, singleUser]);

  const linkLegacyAccount = async (event: FormEvent) => {
    event.preventDefault();
    const user = `${tenant}\\${usernameWithoutTenant}`;

    const { state } = await client.auth.authenticateUsernamePassword(
      user,
      password
    );
    if (state === 'Authenticated') {
      await client.apiOauth2.setEmailAddress('google', googleEmail);
      if (localStorage.getItem('sso-single-user') === null) {
        setState('single-user');
      } else if (localStorage.getItem('debug-auth') === 'true') {
        setState('wait');
      } else {
        setState('finish');
      }
    } else {
      setState('unknown-user');
    }
  };

  useEffect(() => {
    document.title = 'KidsManager | Google Login';
    const code = searchParams.get('code');
    const origin = window.location.origin;
    const tenant = localStorage.getItem('sso-tenant');
    if (code) {
      client.apiOauth2
        .verify(code, origin, tenant)
        .then((response) => {
          if (!response) {
            return;
          }
          client.auth.processAuthResponse(
            response,
            localStorage.getItem('sso-single-user') === 'true'
          );
          setTenant(sessionStorage.getItem('tenant'));
          if (localStorage.getItem('sso-single-user') === null) {
            setState('single-user');
          } else if (localStorage.getItem('debug-auth') === 'true') {
            setState('wait');
          } else {
            setState('finish');
          }
        })
        .catch((error) => {
          if (error instanceof UserNotFoundException) {
            setTenant(error.tenant);
            setGoogleEmail(error.email);
          }
          setState('link');
        });
    }
  }, [searchParams, client]);

  const invalidLogindata = () => {
    return !usernameWithoutTenant || !password;
  };

  const handleWaitClose = () => {
    if (!tenant) {
      return;
    }
    setState('finish');
  };

  const handleSingleUser = (value: boolean) => {
    if (value) {
      localStorage.setItem('sso-single-user', 'true');
      localStorage.setItem('refreshToken', client.auth.refreshToken() || '');
      setSingleUser(true);
    } else {
      localStorage.setItem('sso-single-user', 'false');
      localStorage.removeItem('refreshToken');
      setSingleUser(false);
    }

    if (localStorage.getItem('debug-auth') === 'true') {
      setState('wait');
    } else {
      setState('finish');
    }
  };

  const content = () => {
    switch (state) {
      case 'check':
        return (
          <div className="mx-auto mt-20 max-w-sm">
            <Progress mode="indeterminate" />
            <p className="font-sm pt-1 text-black/50">
              Konto wird überprüft...
            </p>
          </div>
        );
      case 'link':
        return (
          <div className="mx-auto max-w-sm">
            <p>Mit bestehendem Konto verbinden</p>
            <form
              className="flex flex-col gap-2 pt-4"
              onSubmit={linkLegacyAccount.bind(this)}
            >
              <Input
                prefix={`${tenant}\\`}
                placeholder="Benutzername"
                value={usernameWithoutTenant}
                onChange={setusernameWithoutTenant.bind(this)}
              />
              <Input
                placeholder="Passwort"
                type="password"
                value={password}
                onChange={setPassword.bind(this)}
              />
              <div className="text-end">
                <Button disabled={invalidLogindata()}>Verbinden</Button>
              </div>
            </form>
          </div>
        );
      case 'unknown-user':
        return (
          <div>
            <p>Konto nicht gefunden</p>
          </div>
        );
      case 'single-user':
        return (
          <div className="flex flex-col gap-6">
            <p>Bitte auswählen...</p>
            <div>
              <Button onClick={handleSingleUser.bind(this, false)}>
                <div className="flex h-16 w-64 items-center justify-center">
                  <span
                    className="material-icons mx-2"
                    style={{ fontSize: '40px' }}
                  >
                    groups
                  </span>
                  <span className="inline-block px-2">
                    Verschiedene Personen nutzen diesen Computer
                  </span>
                </div>
              </Button>
            </div>
            <div className="mx-auto inline-flex items-center">
              <div className="w-20 border-b border-black" />
              <div className="mx-2">oder </div>
              <div className="w-20 border-b border-black" />
            </div>
            <div>
              <Button onClick={handleSingleUser.bind(this, true)}>
                <div className="flex h-16 w-64 items-center justify-center">
                  <span
                    className="material-icons mx-2"
                    style={{ fontSize: '40px' }}
                  >
                    person
                  </span>
                  <span className="inline-block px-2">
                    Nur ich nutze diesen Computer
                  </span>
                </div>
              </Button>
            </div>
          </div>
        );
      case 'wait':
        return (
          <div>
            <p className="pb-10">SSO-Prozess ist abgeschlossen!</p>
            <Button onClick={handleWaitClose.bind(this)}>Fortsetzen</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative pt-52 text-center">
      <div
        className={`absolute left-1/2 -translate-x-1/2 transition-all duration-500 ease-out ${state ? 'top-16 scale-75' : 'top-28'}`}
      >
        <Logo brand={props.brand} size={52} />
      </div>
      {content()}
    </div>
  );
};
