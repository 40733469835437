import { quickid } from '@kidsmanager/ui-api';
import {
  Checkbox,
  InlineInput,
  SettingsTable,
  TableMenu
} from '@kidsmanager/ui-core';
import { IRosterTemplateShift } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ShiftPatternsProps {
  label: string;
  patterns: IRosterTemplateShift[];
  onChange?: (patterns: IRosterTemplateShift[]) => void;
}

export const ShiftPatterns = (props: ShiftPatternsProps) => {
  const [patterns, setPatterns] = useState<IRosterTemplateShift[]>([]);
  const { t } = useTranslation(['admin-roster']);

  useEffect(() => {
    setPatterns(props.patterns);
  }, [props.patterns]);

  const handleAdd = () => {
    setPatterns((prev) => {
      const next = [
        ...prev,
        {
          id: quickid('SP', 'low'),
          name:
            prev.length === 0 ? t('roster.main_shift') : t('roster.new_shift'),
          start: '08:00',
          duration: 8,
          breaks: false
        }
      ];
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleDuplicate = (pattern: IRosterTemplateShift) => {
    const id = quickid('SP', 'low');
    const newPattern = {
      ...pattern,
      id,
      name: `${pattern.name} (${t('roster.copy')})`
    };
    setPatterns((prev) => {
      const next = [...prev, newPattern];
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleDelete = (pattern: IRosterTemplateShift) => {
    setPatterns((prev) => {
      const next = prev.filter((p) => p.id !== pattern.id);
      setTimeout(() => props.onChange?.(next), 0);
      return next;
    });
  };

  const handleChange = (
    pattern: IRosterTemplateShift,
    property: string,
    value: string,
    type: 'string' | 'number' = 'string'
  ) => {
    setPatterns((prev) => {
      const next = prev.map((p) =>
        p.id === pattern.id
          ? { ...p, [property]: type === 'number' ? parseFloat(value) : value }
          : p
      );
      props.onChange?.(next);
      return next;
    });
  };

  const handleBreaksChange = (
    pattern: IRosterTemplateShift,
    value: boolean
  ) => {
    setPatterns((prev) => {
      const next = prev.map((p) =>
        p.id === pattern.id ? { ...p, breaks: value } : p
      );
      props.onChange?.(next);
      return next;
    });
  };

  return (
    <SettingsTable
      label={props.label}
      columns={[
        { label: t('roster.name'), minWidth: '160px' },
        { label: t('roster.start'), width: '140px' },
        { label: t('roster.duration'), width: '140px' },
        { label: t('roster.breaks'), width: '60px' },
        { label: '', width: '40px' }
      ]}
      onAdd={handleAdd.bind(this)}
    >
      {patterns.map((pattern, index) => (
        <tr key={pattern.id} className="odd:bg-neutral-100">
          <td>
            <InlineInput
              value={pattern.name}
              onChange={(val) => handleChange(pattern, 'name', val)}
            />
          </td>
          <td>
            <InlineInput
              mask="time"
              value={pattern.start}
              onChange={(val) => handleChange(pattern, 'start', val)}
            />
          </td>
          <td>
            <InlineInput
              value={pattern.duration.toString()}
              onChange={(val) =>
                handleChange(pattern, 'duration', val, 'number')
              }
            />
          </td>
          <td className="align-bottom">
            <Checkbox
              checked={pattern.breaks}
              onChange={(val) => handleBreaksChange(pattern, val)}
            />
          </td>
          <td className="text-center">
            <TableMenu
              options={[
                {
                  label: t('common.duplicate'),
                  callback: handleDuplicate.bind(this, pattern)
                },
                {
                  label: t('common.delete'),
                  callback: handleDelete.bind(this, pattern)
                }
              ]}
            />
          </td>
        </tr>
      ))}
    </SettingsTable>
  );
};
