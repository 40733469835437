export interface LabelRotatedProps {
  children: React.ReactNode;
  rotate?: boolean;
  tooltip?: string;
  maxWidth?: string;
}

/**
 * Places the label at a 45 degree angle at the bottom of the column.
 * Applies ellipsis clipping to text that is longer than around 10 characters.
 * If empty, paints a neutral background at the same 45 degree angle.
 */
export const LabelRotated = (props: LabelRotatedProps) => {
  const maxWidth = props.maxWidth || '6rem';
  return props.children ? (
    <div
      title={props.tooltip}
      className={`absolute bottom-0 left-2 origin-top-left overflow-hidden text-ellipsis ${props.rotate && '-rotate-45'}`}
      style={{ maxWidth }}
    >
      {props.children}
    </div>
  ) : (
    <div
      className={`absolute bottom-0 left-2 h-5 w-16 origin-top-left overflow-hidden bg-neutral-100 ${props.rotate && '-rotate-45'}`}
    />
  );
};
