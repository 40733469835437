import { IAuth, fetch } from '../auth';
import { ApiRosterConfig } from './api-roster-config';
import { ApiRosterPlan } from './api-roster-plan';
import { ApiRosterShifts } from './api-roster-shifts';
import { ApiRosterTeam } from './api-roster-team';

export class ApiRoster {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.config = new ApiRosterConfig(this.auth, this.fetch);
    this.shifts = new ApiRosterShifts(this.auth, this.fetch);
    this.team = new ApiRosterTeam(this.auth, this.fetch);
    this.plan = new ApiRosterPlan(this.auth, this.fetch);
  }

  config: ApiRosterConfig;
  shifts: ApiRosterShifts;
  team: ApiRosterTeam;
  plan: ApiRosterPlan;
}
