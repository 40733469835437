import { useMemo } from 'react';
import { Input, Option, Select } from '@kidsmanager/ui-core';
import { nameToColor } from '@kidsmanager/util-common';
import { IRosterTemplateShift } from '@kidsmanager/util-models';
import { InternalShiftSpec } from '../models';
import { endFromStartAndHours } from '../helpers/shift-mananger-helpers';

export interface EditableShiftSpecProps {
  spec: InternalShiftSpec;
  patterns: IRosterTemplateShift[];
  onDeleted?: (index: number | undefined) => void;
  onBlur?: (spec: InternalShiftSpec) => void;
}

export const EditableShiftSpec = (props: EditableShiftSpecProps) => {
  const { spec, options } = useMemo(() => {
    if (props.patterns.length === 0) {
      return { spec: { ...props.spec }, options: [] };
    } else {
      const unique = new Set(props.patterns.map((x) => x.name));
      unique.add(props.spec.name);
      return {
        spec: { ...props.spec },
        options: [...unique]
      };
    }
  }, [props.patterns, props.spec]);

  const handleNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    props.onBlur?.({ ...spec, name: value, color: nameToColor(value) });
  };

  const handleStartChange = (start: string) => {
    const end = start.includes(':')
      ? endFromStartAndHours(start, spec.duration)
      : spec.end;
    props.onBlur?.({ ...spec, start, end });
  };

  const handleDurationChange = (value: string) => {
    const duration = parseFloat(value);
    const end = endFromStartAndHours(spec.start, duration);
    props.onBlur?.({ ...spec, end, duration });
  };

  return options.length === 0 ? null : (
    <li className="m-1 grid grid-cols-[20px_1fr_70px_70px_70px_40px] items-center gap-3">
      <div
        className="h-6 w-6 rounded"
        style={{ backgroundColor: spec.color }}
      ></div>
      <Select
        name="name"
        defaultValue={spec.name}
        onChange={handleNameChange.bind(this)}
      >
        {options.map((name) => (
          <Option key={name} value={name}>
            {name}
          </Option>
        ))}
      </Select>
      <Input
        name="start"
        value={spec.start}
        mask="time"
        onChange={handleStartChange.bind(this)}
      />
      <Input
        name="hrs"
        value={spec.duration?.toString()}
        type="number"
        min={0}
        max={48}
        onChange={handleDurationChange.bind(this)}
      />
      <Input name="end" value={spec.end} mask="time" disabled />
      <span
        className="material-icons cursor-pointer text-black/50"
        onClick={props.onDeleted?.bind(this, spec.index)}
      >
        delete
      </span>
    </li>
  );
};
