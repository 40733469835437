import { IAuth, fetch } from '../auth';
import { ApiAdminUsers } from './api-admin-users';
import { ApiAdminGroups } from './api-admin-groups';
import { ApiAdminRosters } from './api-admin-roster';
import { ApiAdminTemplate } from './api-admin-template';

export class ApiAdmin {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {
    this.users = new ApiAdminUsers(this.auth, this.fetch);
    this.groups = new ApiAdminGroups(this.auth, this.fetch);
    this.template = new ApiAdminTemplate(this.auth, this.fetch);
    this.roster = new ApiAdminRosters(this.auth, this.fetch);
  }

  users: ApiAdminUsers;
  groups: ApiAdminGroups;
  template: ApiAdminTemplate;
  roster: ApiAdminRosters;
}
